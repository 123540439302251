body {
  font-family: 'Courier New', monospace;
}

#root {
  background-color: black;
}

.App {
  position: relative;
  height: 100vh;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.video-container {
  width: 600px;
  height: 600px;
  overflow: hidden;
  position: relative;
}

#bitbybit {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: black;
}

.video-visible {
  opacity: 1;
}

.video-hidden {
  opacity: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  color: #D9EF78;
  font-size: 24px;
  text-align: center;
  transition: opacity 1s ease;
  opacity: 1;
}

.wallet-checker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: black;
  text-align: center;
  min-height: 100vh;
  font-family: 'Courier New', monospace;
}

.input-row, .results-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.input-row {
  align-items: center;
  margin-bottom: 20px;
}

.input-field {
  width: 500px;
  padding: 10px;
  margin: 0 10px 0px 0;
  font-size: 16px;
  font-family: 'Courier New', monospace;
  vertical-align: middle;
  border-radius: 4px;
}

.input-field:focus {
  outline: none;
}

.search-button {
  padding: 12px;
  background-color: #D9EF78;
  border: none;
  cursor: pointer;
  font-size: 16px;
  vertical-align: top;
  font-family: 'Courier New', monospace;
  vertical-align: middle;
  border-radius: 4px;
}

.result {
  color: #D9EF78;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 2;
}

.result span {
  text-align: left;
  flex-grow: 1;
}

.result img {
  width: 25px;
  height: auto;
  margin-left: 20px;
}


.results-container {
  position: relative;
  width: 600px;
  height: 600px;
  overflow: auto;
  z-index: 2;
}

.background-video {
  position: absolute;
  width: 600px;
  height: 600px;
  opacity: 0.5;
  object-fit: cover;
  z-index: 1;
}

.no-match-message {
  color: #D9EF78;
}

/* Responsive styles for small screens */
@media (max-width: 600px) {
  .App {
    flex-direction: column;
    justify-content: flex-start; /* Align content to the top */
    height: 100vh; /* Full viewport height */
    background-color: black; /* Set the background color */
  }

  .video-container, .background-video {
    width: 100vw; /* Full viewport width */
  }

  .background-video {
    object-fit: contain; /* Ensure the video fits within the container */
  }

  .input-row {
    z-index: 3;
    position: relative; /* Required for z-index */
    width: 100%; /* Full width */
    margin-top: 20px; /* Space above the input row */
  }

  .input-field {
    width: 90%; /* Adjust input field width */
  }

  .result span {
    font-size: 14px; /* Smaller font size */
  }

  .result img {
    width: 20px; /* Smaller image size */
    margin-left: 10px; /* Reduced margin */
  }
}

